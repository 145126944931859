<template>
  <div>
    <portal to="v-main"> </portal>
    <v-card-text style="height: 300px; overflow: auto" class="pa-0">
      <a-table-f-api
        ref="tableGoods"
        :api="api"
        :model="modelList"
        :searchable="false"
        :defaults="{
          filters: { object_id: id },
          sort: { key: 'id', order: 'DESC' },
          paramName: 'tabContract',
        }"
        @click="onClickRow($event)"
      >
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.contracts,
    };
  },
  created() {},
  watch: {},
  computed: {
    modelList() {
      let model = this.calcModel("objectContractList", "list");
      return model;
    },
    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },
  },
  methods: {
    onClickRow(d) {
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      if (this.getAccess("menu.contracts")) {
        const resolvedRoute = this.$router.resolve({ name: "contracts_view", params: { id: d.row.id } });
        window.open(resolvedRoute.href, "_blank");
      }
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
